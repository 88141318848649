import jwtDecode from 'jwt-decode'; // Asegúrate de tener la librería jwt-decode instalada
import store from '@/store'; // Importa tu store

export default function guardMyRoute(to, from, next) {
  const urlParams = new URLSearchParams(window.location.search);
  let token = urlParams.get('token');
  let decoded;

  // Si hay token en la URL, lo usamos y almacenamos
  if (token) {
    localStorage.setItem("token", token);
    try {
      decoded = jwtDecode(token);
    } catch (error) {
      console.log("Token inválido");
      if (to.name !== "Login") {
        next({ name: "Login" });
      } else {
        next();
      }
      return;
    }
    store.commit("setUserData", {
      id: decoded.id,
      firstName: decoded.name,
      lastName: decoded.lastName,
      role: 1,
      phone: decoded.phone,
      email: decoded.email,
    });
  } else {
    // Si no hay token en la URL, lo obtenemos del localStorage
    token = localStorage.getItem("token");
    try {
      decoded = jwtDecode(token);
    } catch (error) {
      console.log("Token inválido");
      if (to.name !== "Login") {
        next({ name: "Login" });
      } else {
        next();
      }
      return;
    }
  }

  // Verificar la expiración del token
  if (token) {
    const isExpired = decoded.exp < Date.now() / 1000;
    if (isExpired) {
      localStorage.removeItem("token");
      store.commit('logout');
      if (to.name !== "Login") {
        next({ name: "Login" });
      } else {
        next();
      }
      return;
    }

    // Aquí puedes colocar la lógica de rutas protegidas y redirecciones según el rol
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.getters.getIsLoggedIn) {
        store.commit('login', { token });
        store.commit('setRole', 1);
        next();
        return;
      }
      if (store.getters.role === 1) {
        if (to.path.includes("corredor")) {
          next();
          return;
        } else {
          next({ name: "Corredor" });
          return;
        }
      }
      if (store.getters.role === 4) {
        if (to.path.includes("admin")) {
          next();
          return;
        } else {
          next({ name: "adminRequests" });
          return;
        }
      }
    }

    // Rutas que se deben ocultar para usuarios autenticados
    if (to.matched.some(record => record.meta.hideForAuth) && store.getters.getIsLoggedIn) {
      if (store.getters.role === 1) {
        next({ name: "Corredor" });
        return;
      }
      if (store.getters.role === 4) {
        next({ name: "adminRequests" });
        return;
      }
    }
  } else {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      next({ name: "Login" });
      return;
    }
  }
  
  next();
}

